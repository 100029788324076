import { useEffect, useState, } from 'react';
import { Typography, Alert, } from 'antd';
import { Modal, Spin, Divider, Layout, } from 'antd';

import * as Zendesk from '@wowmaking/zendesk';
import * as Helpdesk from '@wowmaking/helpdesk';

import AnalyticsModule from './modules/analytics';

import { ContactForm } from './ContactForm';

import type { Theme } from './themes';

import classes from './Main.module.scss';

const ZENDESK_TOKEN = '4e91db58d5641902743e6ee69f00eed819d58f1755a1220b2a4b509c7525e746';

interface MainProps {
  appName: string,
  platform: string,
  pageTitle: string,
  appUrl: string,
  address: string,
  infoDataDeletion: string,
  infoAccountDeletion: string,
  showUserNameField: boolean,
  showTicketNumberField: boolean,
  theme: Theme,
  supportTool: 'zendesk' | 'helpdesk',
  helpdeskTeamId: string,
  helpdeskTicketPriority: keyof typeof Helpdesk.TICKET_PRIORITY,
}

export const Main: React.FC<MainProps> = ({
  appName,
  platform,
  pageTitle,
  appUrl,
  address,
  infoDataDeletion,
  infoAccountDeletion,
  showUserNameField,
  showTicketNumberField,
  theme,
  supportTool,
  helpdeskTeamId,
  helpdeskTicketPriority,
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (supportTool === 'zendesk') {
      Zendesk.init({
        authToken: ZENDESK_TOKEN
      });
    }
  }, [supportTool]);

  useEffect(() => {
    AnalyticsModule.trackEvent('form', 'ready');
  }, []);

  const handleFormSubmit = (values: any) => {
    setPending(true);

    const custom_fields = {
      app_name: `${appName} ${platform}`,
      order_id: values.card_number,
      category: values.category,
      idfa: values.username ? `AS/GP Username: ${values.username}` : '',
      idfv: values.ticket_number ? `Ticket Number: ${values.ticket_number}` : '',
    };

    AnalyticsModule.trackEvent('form', 'send');

    return (
      supportTool === 'zendesk'
        ? Zendesk.postTicket({
          ticket: {
            subject: `${appName} ${platform}`,
            mail: values.mail,
            comment: { body: values.comment },
            // @ts-ignore
            custom_fields: Zendesk.transformDeviceInfoToCustomValues(custom_fields),
            requester: { name: values.name, email: values.email },
          },
        })
        : Helpdesk.postTicket({
          ticket: {
            subject: `${appName} ${platform}`,
            mail: values.mail,
            comment: { body: values.comment },
            custom_fields,
            requester: { name: values.name, email: values.email },
            priority: Helpdesk.TICKET_PRIORITY[helpdeskTicketPriority],
            assignment: {
              agentID: '',
              teamID: helpdeskTeamId,
            },
          },
        }))
      .then(() => {
        modal.success({
          title: 'Request has been sent',
          content: 'We will contact you within 24 hours'
        });
        AnalyticsModule.trackEvent('form', 'success');
      })
      .catch(err => {
        modal.error({
          title: 'Something wrong',
        });
        AnalyticsModule.trackEvent('form', 'error', { message: err?.message || err });
        throw err;
      })
      .finally(() => {
        setPending(false);
      });
  }


  return (
    <Spin spinning={pending}>
      {contextHolder}
      <Layout>
        <Layout.Content>
          <div className={classes.App}>
            <div className={classes.Grid}>
              <div className={classes.Header}>
                <Typography.Title style={{ color: theme?.colorTextHeading, fontFamily: theme?.fontFamilyHeading, }}>
                  {theme?.logo && (
                    <img srcSet={theme?.logo} alt={pageTitle} />
                  )}
                  {pageTitle}
                </Typography.Title>
                <Typography.Paragraph>
                  On this website you can access Support Team regarding your recent purchase of the product <Typography.Link href={appUrl}>{appName}</Typography.Link>.
                </Typography.Paragraph>
              </div>
              <div className={classes.Form}>
                <Alert
                  message="To receive information fill in and send this form."
                  description="Support Team will contact you."
                  type="info"
                  showIcon
                  style={{ marginBottom: 16 }}
                />
                <ContactForm
                  showCardNumberField={platform.toLowerCase() === 'web'}
                  showUserNameField={showUserNameField}
                  showTicketNumberField={showTicketNumberField}
                  availableDataDeletion={!!infoDataDeletion}
                  availableAccountDeletion={!!infoAccountDeletion}
                  onSubmit={handleFormSubmit}
                />
              </div>
              <div className={classes.Meta}>
                <Divider />
                {platform.toLowerCase() === 'web' && (
                  <>
                    <Typography.Title level={5}>Security guarantee</Typography.Title>
                    <Typography.Paragraph>PCI DSS guarantees safe processing, transfer, and keeping of information about payment cards Visa, MasterCard, Discover.</Typography.Paragraph>
                    <Divider />
                  </>
                )}
                {!!address && (
                  <>
                    <Typography.Paragraph>{address}</Typography.Paragraph>
                    <Divider />
                  </>
                )}
                {!!infoDataDeletion && (
                  <>
                    <Typography.Paragraph><p dangerouslySetInnerHTML={{ __html: infoDataDeletion }} /></Typography.Paragraph>
                    <Divider />
                  </>
                )}
                {!!infoAccountDeletion && (
                  <>
                    <Typography.Paragraph><p dangerouslySetInnerHTML={{ __html: infoAccountDeletion }} /></Typography.Paragraph>
                    <Divider />
                  </>
                )}
              </div>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    </Spin>
  );
}
