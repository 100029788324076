import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import 'antd/dist/reset.css';

import './index.css';

import App from './App';

Sentry.init({
  dsn: 'https://f06536a06ecb44059df52598f839fb09@sentry.wowmaking.net/73',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <App />
);
