import { useEffect, useState, useMemo, } from 'react';
import { ConfigProvider, } from 'antd';
import { Helmet } from 'react-helmet';

import AnalyticsModule from './modules/analytics';
import RemoteConfigModule from './modules/remote-config';

import { THEMES } from './themes';

import { Main } from './Main';

const MAGNUS_TOKEN = 'vjYNoMBWffmDO3TaQhiHRbGTUWnNkkOq';

function App() {
  const [loaded, setLoaded] = useState(false);

  const [config, setConfig] = useState({
    pageMetaTitle: window.location.hostname,
    pageMetaDescription: '',
    pageTitle: window.location.hostname,
    appName: window.location.hostname,
    appUrl: window.location.href,
    platform: 'Web',
    address: '',
    theme: '',
    infoDataDeletion: '',
    infoAccountDeletion: '',
    showUserNameField: false,
    showTicketNumberField: false,
    supportTool: 'helpdesk' as const,
    helpdeskTeamId: '',
    helpdeskTicketPriority: 'low' as const,
  });

  const theme = useMemo(() => THEMES[config.theme], [config]);

  useEffect(() => {
    AnalyticsModule.init(MAGNUS_TOKEN);
    RemoteConfigModule.fetch(MAGNUS_TOKEN, config)
      .then((r) => {
        // @ts-ignore
        setConfig(r);
      })
      .finally(() => {
        setLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <ConfigProvider theme={theme?.theme}>
      <Helmet>
        <title>{config.pageMetaTitle || config.pageTitle}</title>
        <meta name="description" content={config.pageMetaDescription} />
      </Helmet>
      <Main
        appName={config.appName}
        platform={config.platform}
        pageTitle={config.pageTitle}
        appUrl={config.appUrl}
        address={config.address}
        infoDataDeletion={config.infoDataDeletion}
        infoAccountDeletion={config.infoAccountDeletion}
        showUserNameField={config.showUserNameField}
        showTicketNumberField={config.showTicketNumberField}
        theme={theme}
        supportTool={config.supportTool}
        helpdeskTeamId={config.helpdeskTeamId}
        helpdeskTicketPriority={config.helpdeskTicketPriority}
      />
    </ConfigProvider>
  );
}

export default App;
