import Mutator from '@magnus/react-native-mutator';

import createRemoteConfigSnapshotNormalizer from './normalizer';

const RemoteConfigModule = {
  fetch(token, defaultValues, duration) {
    Mutator.init({
      token,
    });
    return Mutator.fetch(duration)
      .then(() => Mutator.activate())
      .then(createRemoteConfigSnapshotNormalizer(defaultValues))
      .then(({ remoteConfig }) => remoteConfig);
  },
};

export default RemoteConfigModule;
