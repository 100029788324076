import { Form, Input, Checkbox, Button, Select, } from 'antd';

interface ContactFormProps {
  showCardNumberField: boolean,
  showUserNameField: boolean,
  showTicketNumberField: boolean,
  availableDataDeletion: boolean,
  availableAccountDeletion: boolean,
  onSubmit: (values: {
    name: string,
    email: string,
    category: string,
    card_number: string,
    username: string,
    ticket_number: string,
    comment: string,
  }) => Promise<void>,
}
export const ContactForm: React.FC<ContactFormProps> = ({
  showCardNumberField,
  availableDataDeletion,
  availableAccountDeletion,
  showUserNameField,
  showTicketNumberField,
  onSubmit,
}) => {
  const [form] = Form.useForm();

  const handleFormFinish = (values: any) => {
    onSubmit({
      name: values.name,
      email: values.email,
      category: values.category,
      card_number: values.card_number,
      username: values.username,
      ticket_number: values.ticket_number,
      comment: values.comment,
    })
      .then(() => {
        form.resetFields();
      })
  }

  return (
    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      form={form}
      onFinish={handleFormFinish}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input name' }]}
      >
        <Input
          placeholder='Enter Your Name'
          size='large'
        />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input email' }]}
      >
        <Input
          type="email"
          placeholder='Enter Your Email'
          size='large'
        />
      </Form.Item>

      <Form.Item
        label="Request Category"
        name="category"
        rules={[{ required: true, message: 'Please select category' }]}
      >
        <Select
          options={[
            { value: 'BUG', label: 'Bug' },
            { value: 'FEEDBACK', label: 'Feedback' },
            { value: 'SUBSCRIPTION', label: 'Subscription or payment issues' },
            ...(availableDataDeletion ? [{ value: 'DATA_DELETION', label: 'Data deletion' }] : []),
            ...(availableAccountDeletion ? [{ value: 'ACCOUNT_DELETION', label: 'Account deletion' }] : []),
            { value: 'OTHER', label: 'Other' },
          ]}
          placeholder='Select Category'
          size='large'
        />
      </Form.Item>

      {showCardNumberField && (
        <Form.Item
          label="Last 4 digits of the card number"
          name="card_number"
          rules={[{ required: true, message: 'Please input number' }]}
        >
          <Input
            addonBefore={'xxxx-xxxx-xxxx-'}
            placeholder={'xxxx'}
            size='large'
          />
        </Form.Item>
      )}

      {showUserNameField && (
        <Form.Item
          label="App Store/Google Play Username"
          name="username"
        >
          <Input
            placeholder='Enter Your Username'
            size='large'
          />
        </Form.Item>
      )}

      {showTicketNumberField && (
        <Form.Item
          label="Support Ticket Number"
          name="ticket_number"
          help="If you’ve contacted support, provide your ticket number"
        >
          <Input
            placeholder='Enter Your Ticket Number'
            size='large'
          />
        </Form.Item>
      )}

      <Form.Item
        label="Comment"
        name="comment"
        rules={[{ required: true, message: 'Please input comment' }]}
      >
        <Input.TextArea
          placeholder='Enter Your Message'
          size='large'
        />
      </Form.Item>

      <Form.Item
        name="confirm"
        valuePropName="checked"
        rules={[{ required: true, message: 'Please confirm' }]}
      >
        <Checkbox>I confirm that my contact information indicated above is accurate.</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" size='large' style={{ width: 220, fontWeight: 'bold' }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
