import { theme, ThemeConfig } from 'antd';

export interface Theme {
  logo: string,
  colorTextHeading?: string,
  fontFamilyHeading?: string,
  theme: ThemeConfig,
}

export const THEMES: {
  [key: string]: Theme,
} = {
  astroline: {
    logo: `${require('./images/astroline.webp')}, ${require('./images/astroline@2x.webp')} 2x, ${require('./images/astroline@3x.webp')} 3x`,
    colorTextHeading: '#f2e6c4',
    fontFamilyHeading: 'Philosopher',
    theme: {
      token: {
        colorPrimary: '#f2e6c4',
        borderRadius: 10,
        fontSize: 16,
        colorText: 'white',
      },
      algorithm: theme.darkAlgorithm,
      components: {
        Layout: {
          colorBgBody: '#152b35',
        },
        Alert: {
          colorInfo: '#f2e6c4',
          colorInfoBorder: '#f2e6c4',
          colorInfoBg: 'transparent',
        },
        Typography: {
          colorLink: '#f2e6c4',
        },
        Button: {
          borderRadiusLG: 10,
          primaryColor: '#1e3b48',
        },
        Input: {
          colorBgContainer: '#0005',
        }
      }
    }
  },

  pulsebit: {
    logo: `${require('./images/pulsebit.webp')}, ${require('./images/pulsebit@2x.webp')} 2x, ${require('./images/pulsebit@3x.webp')} 3x`,
    colorTextHeading: '#499B92',
    theme: {
      token: {
        colorPrimary: '#499B92',
        borderRadius: 10,
        fontSize: 16,
      },
      components: {
        Layout: {
          colorBgBody: '#ECF4F3',
        },
        Alert: {
          colorInfo: '#499B92',
          colorInfoBorder: '#499B92',
          colorInfoBg: 'transparent',
        },
        Typography: {
          colorLink: '#499B92',
        },
        Button: {
          borderRadiusLG: 40,
        },
      }
    }
  },

  habio: {
    logo: `${require('./images/habio.webp')}, ${require('./images/habio@2x.webp')} 2x, ${require('./images/habio@3x.webp')} 3x`,
    colorTextHeading: '#EE8F61',
    theme: {
      token: {
        colorPrimary: '#EE8F61',
        borderRadius: 10,
        fontSize: 16,
      },
      components: {
        Layout: {
          colorBgBody: '#FCF4F0',
        },
        Alert: {
          colorInfo: '#EE8F61',
          colorInfoBorder: '#EE8F61',
          colorInfoBg: 'transparent',
        },
        Typography: {
          colorLink: '#EE8F61',
        },
        Button: {
          borderRadiusLG: 40,
        },
      }
    }
  },
}