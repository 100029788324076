import EvTruck from '@magnus/react-native-evtruck';
import DeviceProps from '@magnus/react-native-device-props';

import packageInfo from '../../../package.json';

const AnalyticsModule = {
  init(magnusToken) {
    DeviceProps.setAppVersion(packageInfo.version);

    EvTruck.init({
      token: magnusToken,
    });

    this.setUserProperty('domain', window.location.hostname);
  },

  setUserProperty(name, value) {
    EvTruck.setUserProperty(name, value);
  },

  trackEvent(category, action, params) {
    // console.log('trackEvent:', category, action, params);
    const event = `${category}_${action}`;
    EvTruck.trackEvent(event, params);
  },
};

export default AnalyticsModule;
